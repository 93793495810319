import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../assets/icons/minus.svg'
import _imports_1 from '../assets/icons/add.svg'


const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "price" }
const _hoisted_4 = { class: "cart-button-wrapper" }
const _hoisted_5 = { class: "btn-cart-actions" }
const _hoisted_6 = {
  key: 1,
  class: "count"
}
const _hoisted_7 = ["disabled"]

import { toPrice } from '@/pipes/price';
import { plusCartItem, minusCartItem, deleteCart } from '@/services/CartService';
import { langContent } from '@/services/TranslateService';
import store from '@/store';
import DetailPage from '@/views/products/DetailPage.vue';
import { IonImg, modalController, IonIcon, IonBadge } from '@ionic/vue';
import { trashBinOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'CartItem',
  props: ['item', 'cart_item_id', "errors"],
  emits: ['onChange'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

function getExtraOptionText(item) {
   
  const options = [];

  for (const extraOption of item['extraOptions']) {
    options.push(langContent(extraOption.extra_option_name, extraOption.extra_option_name_ar));
  }

  return options.join(", ");
}
  
function onItemImageError(item) {
  item.itemImage = null;
}

async function onItemSelect(item) {
  
  /*window.history.pushState({
    navigationId: window.history.state.navigationId
  }, null, window.location.pathname);*/

  const modal = await modalController.create({
      component: DetailPage,
      componentProps: {
        itemProps: item
      }
  });
  modal.present(); 
  modal.onDidDismiss().then(e => {

    /*if (!e.data || e.data.from != 'native-back-btn') {
      window['history-back-from'] = 'onDidDismiss';
      window.history.back();
    }*/
  });
  //router.push('/item/' + item['slug']);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "cart-item",
    tappable: "",
    onClick: _cache[4] || (_cache[4] = ($event: any) => (onItemSelect(__props.item)))
  }, [
    (__props.item.itemImage)
      ? (_openBlock(), _createBlock(_unref(IonImg), {
          key: 0,
          loading: "lazy",
          onIonError: _cache[0] || (_cache[0] = ($event: any) => (onItemImageError(__props.item))),
          src: 'https://res.cloudinary.com/plugn/image/upload/c_scale,f_jpg,q_80,w_200/restaurants/' + _unref(store).state['restaurant_uuid'] + '/items/' + __props.item.itemImage.product_file_name
        }, null, 8, ["src"]))
      : _createCommentVNode("", true),
    (!__props.item.itemImage && _unref(store).state['store'].logo)
      ? (_openBlock(), _createBlock(_unref(IonImg), {
          key: 1,
          src: 'https://res.cloudinary.com/plugn/image/upload/c_scale,f_jpg,q_100,w_128/restaurants/' 
                    + _unref(store).state['store'].restaurant_uuid + '/logo/' + _unref(store).state['store'].logo
        }, null, 8, ["src"]))
      : _createCommentVNode("", true),
    (!__props.item.itemImage && !_unref(store).state['store'].logo)
      ? (_openBlock(), _createBlock(_unref(IonImg), {
          key: 2,
          class: "store-logo",
          src: "@/assets/icons/store.svg"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(IonBadge), { color: "danger" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.errors), 1)
        ]),
        _: 1
      }),
      _createElementVNode("h5", null, _toDisplayString(_unref(langContent)(__props.item.item_name, __props.item.item_name_ar)), 1),
      (__props.item['extraOptions'] && __props.item['extraOptions'].length > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(getExtraOptionText(__props.item)), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_unref(toPrice)(__props.item.qty * __props.item.item_price)) + " ", 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_unref(store).state['cart'][__props.cart_item_id])
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-minus-cart",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(minusCartItem)(__props.cart_item_id)), ["stop"]))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("img", { src: _imports_0 }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (_unref(store).state['cart'][__props.cart_item_id])
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(store).state['cart'][__props.cart_item_id].qty), 1))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "btn-add-cart",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_unref(plusCartItem)(__props.cart_item_id, __props.item)), ["stop"])),
              disabled: __props.errors
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("img", { src: _imports_1 }, null, -1)
            ]), 8, _hoisted_7)
          ]),
          _createElementVNode("button", {
            class: "btn-remove",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(deleteCart)(__props.cart_item_id)), ["stop"]))
          }, [
            _createVNode(_unref(IonIcon), { icon: _unref(trashBinOutline) }, null, 8, ["icon"])
          ])
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "clearfix" }, null, -1))
  ]))
}
}

})