import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "update-alert",
  dir: "ltr"
}
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "btn-refresh" }

import { IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';


//import store from '@/store';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateAlert',
  setup(__props) {

function refresh() {
        window.location.reload();
    }

    function close() {
        window.location.reload();
        //store.state.appUpdateAvailable = false;//ignore, might keep showing again and again 
        // Implement the close logic here
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('A new version is available')), 1),
      _createElementVNode("div", _hoisted_4, [
        _cache[0] || (_cache[0] = _createTextVNode("   ")),
        _createElementVNode("span", {
          class: "ag a8k",
          tabindex: "0",
          role: "link",
          onClick: refresh
        }, _toDisplayString(_ctx.$t('Refresh')), 1)
      ])
    ]),
    _createElementVNode("div", {
      tabindex: "0",
      role: "button",
      class: "btn-close",
      onClick: close
    }, [
      _createVNode(_unref(IonIcon), { icon: _unref(closeOutline) }, null, 8, ["icon"])
    ])
  ]))
}
}

})