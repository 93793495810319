import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { modalController } from '@ionic/vue';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationToast',
  props: ["message", "params", "position", "type"],
  setup(__props) {

const props = __props;

onMounted(() => {
    setTimeout(() => {
        modalController.dismiss(); 
    }, 1000)
});


return (_ctx: any,_cache: any) => {
  return props
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["alert", 'alert-' + props.type])
      }, _toDisplayString(_ctx.$t(props.message, props.params)), 3))
    : _createCommentVNode("", true)
}
}

})