import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../../assets/icons/close.svg'
import _imports_1 from '../../assets/icons/share.svg'
import _imports_2 from '../../assets/icons/alert.svg'
import _imports_3 from '../../assets/icons/check.svg'
import _imports_4 from '../../assets/icons/minus-circle.svg'
import _imports_5 from '../../assets/icons/add-circle.svg'


const _hoisted_1 = { class: "image-wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "main-video"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "thumbnail-list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "item-title" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "alert alert-warning"
}
const _hoisted_12 = {
  key: 1,
  class: "alert alert-success"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "list-variant" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 3 }
const _hoisted_19 = { class: "image-wrapper" }
const _hoisted_20 = { class: "main-image" }
const _hoisted_21 = { class: "item-title" }
const _hoisted_22 = { class: "item-description" }
const _hoisted_23 = { class: "cart-qty" }
const _hoisted_24 = ["value"]
const _hoisted_25 = ["disabled"]
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "txt-total" }

import { toPrice } from '@/pipes/price';
import router from '@/router';
import { trackEvent, trackView } from '@/services/AnalyticsService';
import { addToCart, openCartModal } from '@/services/CartService';
import { loadItemBySlug } from '@/services/StoreService';
import store from '@/store';
import { IonButtons, IonButton, IonContent, IonTextarea, IonCheckbox, IonRadio, IonRadioGroup, IonItem, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter, IonNote, IonImg, onIonViewDidEnter, modalController, actionSheetController, isPlatform, popoverController, IonSkeletonText } from '@ionic/vue';
import { logoFacebook, logoTwitter, mail } from 'ionicons/icons';
import { onMounted, ref } from 'vue';
import { Share } from '@capacitor/share';
import ItemShare from '@/components/ItemShare.vue';
import { useI18n } from 'vue-i18n';
import { langContent } from '@/services/TranslateService';

declare let TabbyPromo;


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailPage',
  props: ['itemProps'],
  setup(__props) {

const stock_qty = ref(0);
const item = ref();
const item_variant_uuid = ref(null);
const variant = ref();
const mainImage = ref();
const mainVideo = ref();
const item_price = ref();
const haveTextOption = ref(false);
const selectedOptions = ref({});

const isValid = ref(false);

const qty = ref(1);

const props = __props;

const { t } = useI18n({ useScope: "global" });

let tabbyPromoDetail; 

onMounted(() => {
    trackView('item-detail-page');

    if(props.itemProps.itemImages && props.itemProps.itemImages.length > 0)
        setMainImage(props.itemProps.itemImages[0]);

    popupItemData(props.itemProps.slug);

});

onIonViewDidEnter(() => {

    const slug = router.currentRoute.value.params['slug'];

    popupItemData(slug);
});

async function openCart() {
  
  store.dispatch('openCart', {});

  /*  window.history.pushState({
    navigationId: window.history.state.navigationId
  }, null, window.location.pathname);

  const modal = await modalController.create({
      component: CartPage,
      cssClass: 'popup-modal'
  });
  modal.present();*/
}

/**
 * validate each option 
 */
function validate() {

    if (item.value.item_type == 2)  {
        isValid.value = true; 
        return;
    }

    //stock validation 
    //todo: cart validation store.state['cart'][item.item_uuid]  

    if (item.value.track_quantity && qty.value > item.value.stock_qty) {
        isValid.value = false; 
        return;
    }

    //if non variant with option 
    
    for(let option of item.value.options) {

        //required field validation 

        if ((option.is_required || option.min_qty > 0) && !selectedOptions.value[option.option_id]) {
            isValid.value = false; 
            return;   
        }

        //mix qty validation 

        if (
            selectedOptions.value[option.option_id] && 
            option.min_qty > 0 && selectedOptions.value[option.option_id].length == 0
        ) {
            isValid.value = false; 
            return;  
        }

        //max qty validation

        if (
            selectedOptions.value[option.option_id] && 
            selectedOptions.value[option.option_id].length > option.max_qty
        ) {
            isValid.value = false; 
            return;  
        }
    }

    isValid.value = true; 
    return;   
}

function setMainVideo(video) {
    mainImage.value = null;
    mainVideo.value = "https://www.youtube.com/embed/" + video.youtube_video_id;
}

function setMainImage(image) {  
    mainVideo.value = null;
    mainImage.value =  'https://res.cloudinary.com/plugn/image/upload/q_auto:eco,w_500/restaurants/' + store.state['restaurant_uuid'] + '/items/' + image.product_file_name;
}

async function shareItem(ev) {

    trackEvent("Item Shared", "name", item.value.item_name, item_price.value, item.value.item_uuid);

    const subject = item.value.item_name;
    const message = 'Checkout ' + item.value.item_name + ' at ' + store.state['store'].name;
    const url = window.location.href;

    try {
        Share.canShare().then(async canShare => {
 
            if (canShare) {

                await Share.share({
                    title: subject,
                    text: message,
                    url: url,
                    dialogTitle: t('Share with buddies'),
                });
            } 
        }).catch(async err => {
            if(isPlatform('desktop')) {
                const popover = await popoverController.create({
                    component: ItemShare,
                    componentProps: {
                        subject,
                        message,
                        url
                    },
                    event: ev,
                });
                await popover.present();
            } else {
                shareActionSheet(subject, message, url);
            }
        });
    } catch (err) {
        console.log(err);
    }
}

/**
 * show share action sheet for desktop 
 */
async function shareActionSheet(subject, message, url) {

    const actionSheet = await actionSheetController.create({
        header: t('Share'),
        cssClass: 'action-sheet-md',
        buttons: [
            {
                text: t('Facebook'),
                icon: logoFacebook,
                handler: () => {
                    const a = 'https://www.facebook.com/sharer/sharer.php?u=' + url;
                    window.open(a, '_blank');
                }
            },
            {
                text: t('Twitter'),
                icon: logoTwitter,
                handler: () => {
                    const a = 'https://twitter.com/intent/tweet?text=' + message + '&url=' + url + '&related=';
                    window.open(a, '_blank');
                }
            },
            {
                text: t('Email'),
                icon: mail,
                handler: () => {
                    const a = 'mailto:?subject=' + subject + '&body=' + encodeURI(message) + ' ' + url;
                    window.open(a, '_blank');
                }
            },
        ]
    });

    await actionSheet.present();
}

/**
 * load item details 
 * @param slug 
 */
function popupItemData(slug) {

    loadItemBySlug(slug).then(res => {

        item.value = res;

        tabbyPromoDetail = res.tabbyPromo;

        renderTabbyPromo();
        
        if (item.value.item_type == 2 && !variant.value) {

            if (item.value.itemVariants.length == 0) {

                //variants missing 

                router.push('/')
                return;
            }

            onVariantSelection(item.value.itemVariants[0]);
        }

        if (item.value.item_type == 1) {
            stock_qty.value = item.value.stock_qty;
        } else {
            stock_qty.value = variant.value.stock_qty;
        }

        for (const option of res.options) {
            if (option.option_type == 3) {
                haveTextOption.value = true;
                break;
            }
        }

        if(!mainImage.value) {
            if(item.value.itemImages.length > 0)
                setMainImage(item.value.itemImages[0]);
            else if(item.value.itemVideos.length > 0)
                setMainVideo(item.value.itemVideos[0]);
        } 
 
        //set initial value 

        updateItemPrice();
 
    }).catch((e) => { 
        console.error(e);
    });
}

function renderTabbyPromo() {

    if (tabbyPromoDetail && tabbyPromoDetail.tabby_promo_enabled) {

        const price = toPrice(item_price.value, null, null, false);

        new TabbyPromo({
            selector: '#tabbyPromo-' + item.value.item_uuid,
            currency: store.state['currency_pref'],
            price: price,
            lang: tabbyPromoDetail.lang,
            theme: tabbyPromoDetail.promo_theme,
            installmentsCount: tabbyPromoDetail.tabby_installmentsCount,
            productType: tabbyPromoDetail.productType,
            sourcePlugin: 'plugn',
            source: 'product'
        });
    }
}

function increaseQty() {
    qty.value++;
}

function decreaseQty() {
    if (qty.value == 0)
        return;

    qty.value--;
}

function onItemImageError(index) {
    delete item.value.itemImages[index];
}

function onVariantSelection(itemVariant) {

    variant.value = itemVariant;

    stock_qty.value = variant.value.stock_qty;

    item_variant_uuid.value = itemVariant.item_variant_uuid;

    const variantOptions = {};

    variant.value.itemVariantOptions.forEach(itemVariantOption => {

        if (!variantOptions[itemVariantOption.option_id])
            variantOptions[itemVariantOption.option_id] = [];

        variantOptions[itemVariantOption.option_id].push({
            extra_option_id: itemVariantOption.extra_option_id,
            extra_option_name: itemVariantOption.extraOption.extra_option_name,
            extra_option_name_ar: itemVariantOption.extraOption.extra_option_name_ar,
            extra_option_price: itemVariantOption.extraOption.extra_option_price,
            option_id: itemVariantOption.option_id,
            stock_qty: null
        });
    });

    selectedOptions.value = variantOptions;

    if(variant.value.itemVariantImages.length > 0) {
        setMainImage(variant.value.itemVariantImages[0]);
    //todo: variant videos in future     
    //} else if(variant.value.itemVideos.length > 0) {
    //    setMainVideo(variant.value.itemVideos[0]);
    }
        
    updateItemPrice();
}

function onTextChange(event, option) {

    selectedOptions.value[option.option_id] = [{
        extra_option_name: event.target.value,
        extra_option_name_ar: event.target.value,
        option_id: option.option_id,
        extra_option_price: event.target.value.length > 0 ?
            option.option_price : 0
    }];

    updateItemPrice();
}

function onRadioChange(event, option) {

    if (event.detail.value) {
        const extraOption = option.extraOptions.find(eo => eo.extra_option_id == event.detail.value);

        if (!selectedOptions.value[option.option_id])
            selectedOptions.value[option.option_id] = [];

        selectedOptions.value[option.option_id].push(extraOption);
    }
    else {
        delete selectedOptions.value[option.option_id];
    }

    updateItemPrice();
}

function onCheckboxChange(event, option, extraOption) {

    if (!selectedOptions.value[option.option_id])
        selectedOptions.value[option.option_id] = [];

    if (event.detail.checked) {
        selectedOptions.value[option.option_id].push(extraOption);
    }
    else {
        selectedOptions.value[option.option_id] = selectedOptions.value[option.option_id]
            .filter(eo => eo.extra_option_id != extraOption.extra_option_id);
    }

    updateItemPrice();
}

function updateItemPrice() {

    let price = item.value.item_type == 1 ? parseFloat(item.value.item_price) :
        parseFloat(variant.value.price);

    for (const i in selectedOptions.value) {

        for (const j in selectedOptions.value[i]) {
            price += parseFloat(selectedOptions.value[i][j].extra_option_price);
        }
    }

    item_price.value = price;

    validate();

    renderTabbyPromo();
}

function addItemToCart() {

    //todo: add validation + show error toast for error

    const extraOptions = [];

    for (const i in selectedOptions.value) {

        for (const j in selectedOptions.value[i]) {
            extraOptions.push(selectedOptions.value[i][j]);
        }
    }

    item.value.item_price = item_price.value;

    if (item.value.itemImages && item.value.itemImages.length > 0)
        item.value.itemImage = item.value.itemImages[0];

    if (addToCart(item.value, qty.value, extraOptions, variant.value)) {
        dismiss();
        openCartModal(store.state);
    }
}

function dismiss() {
    modalController.getTop().then(o => {
        if (o) {
            modalController.dismiss();
        } else if (window.history.length > 0) {
            router.back();
        } else {
            router.push('/');
        }
    });
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonButtons), { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButtons), { slot: "start" }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", {
                        type: "button",
                        color: "primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (dismiss()))
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("img", { src: _imports_0 }, null, -1)
                      ]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (item.value)
                ? (_openBlock(), _createBlock(_unref(IonTitle), { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langContent)(item.value.item_name, item.value.item_name_ar)), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_unref(IonButtons), { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButton), {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (shareItem($event)))
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createElementVNode("img", { src: _imports_1 }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), { fullscreen: true }, {
        default: _withCtx(() => [
          (item.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_1, [
                  (mainImage.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "main-image",
                        style: _normalizeStyle({ backgroundImage: 'url(' + mainImage.value + ')' })
                      }, null, 4))
                    : _createCommentVNode("", true),
                  (mainVideo.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("iframe", {
                          width: "100%",
                          height: "400px",
                          src: mainVideo.value,
                          frameborder: "0",
                          allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                          allowfullscreen: ""
                        }, null, 8, _hoisted_3)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    (variant.value && variant.value.itemVariantImages)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(variant.value.itemVariantImages, (itemImage, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            tappable: "",
                            class: "thumbnail",
                            onClick: ($event: any) => (setMainImage(itemImage)),
                            key: index
                          }, [
                            itemImage
                              ? (_openBlock(), _createBlock(_unref(IonImg), {
                                  key: 0,
                                  loading: "lazy",
                                  onIonError: ($event: any) => (onItemImageError(index)),
                                  src: 'https://res.cloudinary.com/plugn/image/upload/c_scale,f_jpg,q_80,w_200/restaurants/' + _unref(store).state['restaurant_uuid'] + '/items/' + itemImage.product_file_name
                                }, null, 8, ["onIonError", "src"]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_5))
                        }), 128))
                      : _createCommentVNode("", true),
                    (item.value.itemImages)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item.value.itemImages, (itemImage, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            tappable: "",
                            class: "thumbnail",
                            onClick: ($event: any) => (setMainImage(itemImage)),
                            key: index
                          }, [
                            itemImage
                              ? (_openBlock(), _createBlock(_unref(IonImg), {
                                  key: 0,
                                  loading: "lazy",
                                  onIonError: ($event: any) => (onItemImageError(index)),
                                  src: 'https://res.cloudinary.com/plugn/image/upload/c_scale,f_jpg,q_80,w_200/restaurants/' + _unref(store).state['restaurant_uuid'] + '/items/' + itemImage.product_file_name
                                }, null, 8, ["onIonError", "src"]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_6))
                        }), 128))
                      : _createCommentVNode("", true),
                    (item.value.itemVideos)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(item.value.itemVideos, (video, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            tappable: "",
                            class: "thumbnail",
                            onClick: ($event: any) => (setMainVideo(video)),
                            key: index
                          }, [
                            video
                              ? (_openBlock(), _createBlock(_unref(IonImg), {
                                  key: 0,
                                  loading: "lazy",
                                  onIonError: ($event: any) => (onItemImageError(index)),
                                  src: 'https://i.ytimg.com/vi/' + video.youtube_video_id + '/hqdefault.jpg'
                                }, null, 8, ["onIonError", "src"]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_7))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", {
                  id: 'tabbyPromo-' + item.value.item_uuid
                }, null, 8, _hoisted_8),
                _createElementVNode("h5", _hoisted_9, _toDisplayString(_unref(langContent)(item.value.item_name, item.value.item_name_ar)), 1),
                _createElementVNode("p", {
                  class: "item-description",
                  innerHTML: _unref(langContent)(item.value.item_description, item.value.item_description_ar)
                }, null, 8, _hoisted_10),
                (haveTextOption.value || (item.value.item_type == 1 && item.value.options && item.value.options.length > 0))
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.value.options, (option, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "option-wrapper",
                        key: i
                      }, [
                        _createElementVNode("h5", null, [
                          _createTextVNode(_toDisplayString(_unref(langContent)(option.option_name, option.option_name_ar)) + " ", 1),
                          ((option.is_required || option.min_qty > 0) && !selectedOptions.value[option.option_id])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _cache[7] || (_cache[7] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('Required')), 1)
                              ]))
                            : _createCommentVNode("", true),
                          ((option.is_required || option.min_qty > 0) && selectedOptions.value[option.option_id])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _cache[8] || (_cache[8] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('Required')), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        (option.min_qty > 0 && option.max_qty > 1)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t('Select at least {min}', { min: option.min_qty })), 1))
                          : _createCommentVNode("", true),
                        (option.min_qty == 0 && option.max_qty > 1)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.$t('Select up to {max}', { min: option.max_qty })), 1))
                          : _createCommentVNode("", true),
                        (option.option_type == 3)
                          ? (_openBlock(), _createBlock(_unref(IonItem), { key: 2 }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonTextarea), {
                                  onIonInput: $event => onTextChange($event, option)
                                }, null, 8, ["onIonInput"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (option.option_type == 2 || (!option.option_type && option.max_qty == 1))
                          ? (_openBlock(), _createBlock(_unref(IonRadioGroup), {
                              key: 3,
                              allowEmptySelection: true,
                              onIonChange: $event => onRadioChange($event, option)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.extraOptions, (extraOption, ei) => {
                                  return (_openBlock(), _createBlock(_unref(IonItem), { key: ei }, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(IonRadio), {
                                        mode: "md",
                                        "label-placement": "end",
                                        justify: "start",
                                        disabled: (extraOption.stock_qty !== null && extraOption.stock_qty <= 0),
                                        value: extraOption.extra_option_id
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_unref(langContent)(extraOption.extra_option_name, extraOption.extra_option_name_ar)), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["disabled", "value"]),
                                      (extraOption.extra_option_price > 0)
                                        ? (_openBlock(), _createBlock(_unref(IonNote), {
                                            key: 0,
                                            slot: "end"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" +" + _toDisplayString(_unref(toPrice)(extraOption.extra_option_price)), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["onIonChange"]))
                          : _createCommentVNode("", true),
                        (option.option_type == 1 || (!option.option_type && option.max_qty != 1))
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(option.extraOptions, (extraOption, ei) => {
                              return (_openBlock(), _createBlock(_unref(IonItem), { key: ei }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(IonCheckbox), {
                                    mode: "md",
                                    "label-placement": "end",
                                    justify: "start",
                                    onIonChange: $event => onCheckboxChange($event, option, extraOption),
                                    disabled: (extraOption.stock_qty !== null && extraOption.stock_qty <= 0)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(langContent)(extraOption.extra_option_name, extraOption.extra_option_name_ar)), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onIonChange", "disabled"]),
                                  (extraOption.extra_option_price > 0)
                                    ? (_openBlock(), _createBlock(_unref(IonNote), {
                                        key: 0,
                                        slot: "end"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" +" + _toDisplayString(_unref(toPrice)(extraOption.extra_option_price)), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  : _createCommentVNode("", true),
                (item.value.item_type == 2)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("h6", null, _toDisplayString(_ctx.$t("Variations")), 1),
                      _createElementVNode("ul", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value.itemVariants, (itemVariant, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            tappable: "",
                            onClick: ($event: any) => (onVariantSelection(itemVariant)),
                            class: _normalizeClass({ selected: itemVariant.item_variant_uuid == item_variant_uuid.value }),
                            key: index
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemVariant.itemVariantOptions, (variantOption, indexOption) => {
                              return (_openBlock(), _createElementBlock("span", { key: indexOption }, [
                                _createElementVNode("b", null, _toDisplayString(_unref(langContent)(variantOption.option.option_name, variantOption.option.option_name_ar)), 1),
                                _createTextVNode(" " + _toDisplayString(_unref(langContent)(variantOption.extraOption.extra_option_name, variantOption.extraOption.extra_option_name_ar)), 1)
                              ]))
                            }), 128))
                          ], 10, _hoisted_16))
                        }), 128))
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (stock_qty.value > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t('In Stock')) + ": " + _toDisplayString(stock_qty.value), 1))
                  : _createCommentVNode("", true),
                (stock_qty.value == 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t('Out of Stock')), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (!item.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_unref(IonSkeletonText), { animated: true })
                  ])
                ]),
                _createElementVNode("h5", _hoisted_21, [
                  _createVNode(_unref(IonSkeletonText), {
                    animated: true,
                    style: {"width":"70%"}
                  })
                ]),
                _createElementVNode("p", _hoisted_22, [
                  _createVNode(_unref(IonSkeletonText), {
                    animated: true,
                    style: {"width":"80%"}
                  }),
                  _createVNode(_unref(IonSkeletonText), {
                    animated: true,
                    style: {"width":"70%"}
                  }),
                  _createVNode(_unref(IonSkeletonText), {
                    animated: true,
                    style: {"width":"75%"}
                  }),
                  _createVNode(_unref(IonSkeletonText), {
                    animated: true,
                    style: {"width":"60%"}
                  })
                ])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (item.value && (item.value.track_quantity == false || stock_qty.value > 0))
        ? (_openBlock(), _createBlock(_unref(IonFooter), { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonToolbar), null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("button", {
                      class: "btn-minus",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (decreaseQty()), ["stop"]))
                    }, _cache[9] || (_cache[9] = [
                      _createElementVNode("img", { src: _imports_4 }, null, -1)
                    ])),
                    _createElementVNode("input", {
                      type: "number",
                      class: "count",
                      value: qty.value
                    }, null, 8, _hoisted_24),
                    _createElementVNode("button", {
                      class: "btn-plus",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (increaseQty()), ["stop"])),
                      disabled: qty.value == stock_qty.value
                    }, _cache[10] || (_cache[10] = [
                      _createElementVNode("img", { src: _imports_5 }, null, -1)
                    ]), 8, _hoisted_25)
                  ]),
                  _createElementVNode("button", {
                    class: "add-to-cart",
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (addItemToCart()), ["stop"])),
                    disabled: !isValid.value
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Add to Cart')) + " ", 1),
                    _cache[11] || (_cache[11] = _createElementVNode("span", { class: "sep" }, "·", -1)),
                    _createElementVNode("span", _hoisted_27, _toDisplayString(_unref(toPrice)(qty.value * item_price.value)), 1)
                  ], 8, _hoisted_26)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})