import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import store from '@/store';
import { IonIcon, IonItem, IonLabel, popoverController } from '@ionic/vue';
import { checkmark } from 'ionicons/icons';
import { inject } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageList',
  setup(__props) {

const i18n: any = inject('i18n');
    
function setLanguage(code) {
  
  const lang = code == 'en'? {
    code: 'en',
    name: 'English'
  }: {
    name: 'عربى',
    code: 'ar'
  }; 

    i18n.global.locale.value = code;

    if(code == "ar") {
        document.querySelector('html').setAttribute("dir", 'rtl');
    } else {
        document.querySelector('html').setAttribute("dir", 'ltr');
    }
    
  store.commit('setLanguagePref', lang);

  popoverController.dismiss();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonItem), {
      tappable: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (setLanguage('en')))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonLabel), null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("h5", null, "English", -1)
          ])),
          _: 1
        }),
        (_unref(store).state.language && _unref(store).state.language.code == 'en')
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: _unref(checkmark)
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_unref(IonItem), {
      tappable: "",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (setLanguage('ar')))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonLabel), null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("h5", null, "عربى", -1)
          ])),
          _: 1
        }),
        (_unref(store).state.language && _unref(store).state.language.code == 'ar')
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: _unref(checkmark)
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}
}

})