import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonIcon, IonItem, IonLabel, IonList, popoverController } from '@ionic/vue';
import { logoFacebook, logoTwitter, mail } from 'ionicons/icons';
 

export default /*@__PURE__*/_defineComponent({
  __name: 'ItemShare',
  props: ['subject','message', 'url'],
  setup(__props) {



function dismiss() {
    popoverController.dismiss();
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonList), {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (dismiss()))
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonItem), {
        lines: "none",
        target: "_blank",
        href: 'https://twitter.com/intent/tweet?text=' + __props.message + '&url=' + __props.url + '&related='
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), { icon: _unref(logoTwitter) }, null, 8, ["icon"]),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Twitter')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_unref(IonItem), {
        lines: "none",
        target: "_blank",
        href: 'https://www.facebook.com/sharer/sharer.php?u=' + __props.url
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), { icon: _unref(logoFacebook) }, null, 8, ["icon"]),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Facebook')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_unref(IonItem), {
        lines: "none",
        target: "_blank",
        href: 'mailto:?subject=' + __props.subject + '&body=' + encodeURI(__props.message) + ' ' + __props.url
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), { icon: _unref(mail) }, null, 8, ["icon"]),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Email')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["href"])
    ]),
    _: 1
  }))
}
}

})