import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "powered-section" }
const _hoisted_2 = { href: "https://plugn.io/?utm_source={{authService.store.name}}&utm_medium=poweredbylink" }
const _hoisted_3 = { class: "english" }

import { IonIcon } from '@ionic/vue';
import { openOutline } from 'ionicons/icons';

export default /*@__PURE__*/_defineComponent({
  __name: 'CopyRights',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.$t("Powered by")) + " ", 1),
    _createElementVNode("a", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _cache[0] || (_cache[0] = _createTextVNode(" Plugn ")),
        _createVNode(_unref(IonIcon), { icon: _unref(openOutline) }, null, 8, ["icon"])
      ])
    ])
  ]))
}
}

})